


























































import { PropType, ref, computed, inject } from '@vue/composition-api';

export default {
  props: {
    value: {
      required: true,
      type: Object
    },
    fetchProgram: {
      required: true,
      type: [Object, Function]
    }
  },
  setup(props: any, ctx: any) {
    const readonly = inject('readonly', false);
    const programDoc: any = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const savePresetProgress = ref(false);
    const connectionList = ['Google', 'Outlook'];
    const index = programDoc.value.data.adks.findIndex(obj => {
      if (obj) {
        return obj.name === 'meetings';
      }
      return false;
    });

    const connection = ref('');
    connection.value = programDoc.value.data.adks[index].connection || 'Google';
    function save() {
      savePresetProgress.value = true;
      if (!programDoc.value.data.adks[index].connection) {
        programDoc.value.data.adks[index].connection = connection.value;
      }
      return new Promise((resolve, reject) => {
        programDoc.value.update();
        props.fetchProgram();
        savePresetProgress.value = false;
        resolve(true);
      });
    }

    const setupInstructions = ref({
      description: '',
      instructions: ['', '', '']
    });

    return {
      setupInstructions,
      index,
      programDoc,
      save,
      readonly,
      connection,
      connectionList,
      savePresetProgress
    };
  }
};
